import { useMutation } from "@apollo/client";

import { UPDATE_EVENT } from "api/graphql/mutations/event";
import { useNotificationContext } from "context/notificationContext";

const useUpdateEvent = () => {
  const [update, { data, loading, error }] = useMutation(UPDATE_EVENT);
  const { addErrorNotification } = useNotificationContext();

  const updateEvent = async (eventData = {}) => {
    try {
      const { id, title, description, monitorsCount, startsAt, endsAt } =
        eventData;

      const updatedEvent = await update({
        variables: {
          input: { id, title, description, monitorsCount, startsAt, endsAt },
        },
      });

      return updatedEvent?.data?.updateEvent?.event;
    } catch (err) {
      addErrorNotification({ content: err?.message, isCloseable: true });
    }
  };

  return {
    updateEvent,
    updatedEvent: data?.createEvent?.event,
    updateLoading: loading,
    updateError: error,
  };
};

export default useUpdateEvent;
