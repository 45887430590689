import EventCard from "components/admin/EventCard";
import { List, Message } from "./styled";

const EventsList = ({ list = [] }) => {
  return list?.length > 0 ? (
    <List>
      {list.map(({ cursor, node: event }) => (
        <EventCard key={event.id} event={event} cursor={cursor} />
      ))}
    </List>
  ) : (
    <Message>There&apos;s nothing here</Message>
  );
};

export default EventsList;
