const Select = ({ id, name, label, options, error, ...restProps }) => {
  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}

      <select id={id} className="form-select" name={name} {...restProps}>
        {options.map(({ label: optionLabel, value }) => (
          <option key={value} value={value}>
            {optionLabel}
          </option>
        ))}
      </select>

      {error && <small className="text-danger">{error}</small>}
    </div>
  );
};

export default Select;
