const getClassNames = (classes = []) => {
  const classesString = classes.filter((cl) => !!cl).join(" ");

  if (classesString.length) {
    return classesString;
  }
  return null;
};

export default getClassNames;
