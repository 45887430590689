import styled, { css } from "styled-components";

const swiperCustomStyles = () => css`
  .swiper,
  .swiper-wrapper,
  .swiper-slide {
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    width: 100% !important;
    visibility: hidden;
    opacity: 0;
  }

  .swiper-slide-active {
    visibility: visible;
    opacity: 1;
  }
`;

export const SliderWrapper = styled.div(
  ({ theme: { layoutYPadding } }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100vh - ${layoutYPadding} * 2);
    overflow: hidden;
    ${swiperCustomStyles()}
  `
);
