import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import { DateInput } from "./styled";

const DateField = ({
  selectedDate,
  onChange = () => {},
  dateParams = {},
  placeholderText = "MMM dd, yyyy",
  dateFormat = "MMM dd, yyyy",
  customStyles,
  ...restProps
}) => {
  const { minDate, maxDate } = dateParams;

  return (
    <DateInput customStyles={customStyles} {...restProps}>
      <DatePicker
        customInput={<input aria-label="Date" />}
        selected={selectedDate}
        onChange={onChange}
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholderText}
      />
    </DateInput>
  );
};

export default DateField;
