import { gql } from "@apollo/client";
import eventFragment from "../fragments/eventFragment";

export const CREATE_EVENT = gql`
  mutation createEvent(
    $input: InputsEventsCreateInput!
    $queueAfter: String
    $queueBefore: String
    $queueFirst: Int
    $queueLast: Int
    $nftAfter: String
    $nftBefore: String
    $nftFirst: Int
    $nftLast: Int
  ) {
    createEvent(input: $input) {
      event {
        ...eventFragment
      }
    }
  }
  ${eventFragment}
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent(
    $input: InputsEventsUpdateInput!
    $queueAfter: String
    $queueBefore: String
    $queueFirst: Int
    $queueLast: Int
    $nftAfter: String
    $nftBefore: String
    $nftFirst: Int
    $nftLast: Int
  ) {
    updateEvent(input: $input) {
      event {
        ...eventFragment
      }
    }
  }
  ${eventFragment}
`;

export const ARCHIVE_EVENT = gql`
  mutation archiveEvent(
    $input: InputsEventsArchiveInput!
    $queueAfter: String
    $queueBefore: String
    $queueFirst: Int
    $queueLast: Int
    $nftAfter: String
    $nftBefore: String
    $nftFirst: Int
    $nftLast: Int
  ) {
    archiveEvent(input: $input) {
      event {
        ...eventFragment
      }
    }
  }
  ${eventFragment}
`;
