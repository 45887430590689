import { css } from "styled-components";

export const buttonStyles = ({
  theme: { colors, down, breakpoints },
  isOutline,
}) => css`
  --button-bg: ${isOutline ? "transparent" : colors.yellow};
  --button-color: ${isOutline ? colors.yellow : colors.black};
  --button-hover-bg: ${isOutline ? colors.yellow : colors.yellowDark};

  border-radius: 50rem;
  ${isOutline &&
  css`
    border-width: 0.18rem;
    border-color: ${colors.yellow};
    border-radius: 50rem;

    &:focus,
    &:hover {
      color: ${colors.black};
      background-color: ${colors.yellow};
    }
  `}

  ${down(breakpoints.sm)} {
    width: 100%;
  }
`;
