import styled, { css } from "styled-components";

export const Item = styled.li`
  display: flex;

  img {
    position: relative;
    width: 100%;
    object-fit: cover;
  }
`;

export const ImageOverlay = styled.div(
  ({ theme: { zIndex, colors } }) => css`
    position: absolute;
    z-index: ${zIndex.contentMin};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.white50};
  `
);
