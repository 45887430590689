import { useNavigate } from "react-router-dom";

import useArchiveEvent from "api/hooks/event/useArchiveEvent";
import { useNotificationContext } from "context/notificationContext";
import { Button } from "shared/ui/buttons";

import { ActionsWrapper } from "../styled";

const Actions = ({ eventData, isDefaultGallerySelected, loading }) => {
  const { archiveEvent, archiveLoading } = useArchiveEvent();
  const navigate = useNavigate();
  const { addNotification } = useNotificationContext();
  const { id, canArchive } = eventData || {};
  const saveButtonText = id ? "Update" : "Create";

  const archive = () => {
    archiveEvent(id);
    navigate("/admin/events/active");
    addNotification({ content: "Event has been archived!" });
  };

  return (
    <ActionsWrapper>
      <Button type="submit" loading={loading}>
        {isDefaultGallerySelected ? "Select NFTs" : saveButtonText}
      </Button>

      {canArchive.value && (
        <Button color="danger" loading={archiveLoading} onClick={archive}>
          Archive
        </Button>
      )}
    </ActionsWrapper>
  );
};

export default Actions;
