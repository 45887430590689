import { useNavigate } from "react-router-dom";

import Dropdown from "shared/ui/Dropdown";
import { TextButton } from "shared/ui/buttons";

import { dropdownItemStyles } from "../styled";

const ActionsDropdown = ({ event, openModal }) => {
  const { id, canEdit } = event || {};
  const navigate = useNavigate();

  const openEditForm = () => {
    navigate(`/admin/event/${id}`);
  };

  return (
    <Dropdown isActionOutlineBtn actionText="...">
      <TextButton onClick={openModal} customStyles={dropdownItemStyles}>
        Event QR Code
      </TextButton>

      {canEdit.value &&
        <TextButton onClick={openEditForm} customStyles={dropdownItemStyles}>
          Edit Event
        </TextButton>
      }
    </Dropdown>
  );
};

export default ActionsDropdown;
