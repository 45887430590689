import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import { GENERATE_NONCE, AUTHENTICATE } from "api/graphql/mutations/auth";
import { getStorageData, setStorageData } from "services/localStorageService";
import { ACCESS_TOKEN } from "shared/constants/auth";
import { useNotificationContext } from "context/notificationContext";
import { useWalletConnection } from "context/walletConnectionContext";

const useAuth = (onCompleted = () => {}) => {
  const [loading, setLoading] = useState(false);

  const [generateMessage] = useMutation(GENERATE_NONCE);
  const [authenticateWithMessage] = useMutation(AUTHENTICATE, {
    onCompleted: (data) => {
      const { authenticate } = data || {};
      const { accessToken } = authenticate || {};

      setStorageData(ACCESS_TOKEN, accessToken);
      if (onCompleted) onCompleted(data);
    },
  });
  const { addErrorNotification } = useNotificationContext();
  const { disconnect } = useWalletConnection();

  const isLoggedIn = () => !!getStorageData(ACCESS_TOKEN);

  const login = useCallback(
    async (account) => {
      if (isLoggedIn()) return false;

      setLoading(true);

      try {
        const { data } = await generateMessage({
          variables: { input: { ethAccount: account } },
        });
        const { ethAccount, nonce: message } = data?.generateNonce || {};
        const signature = await window.ethereum.request({
          method: "personal_sign",
          params: [message, ethAccount],
        });

        const { data: authData } = await authenticateWithMessage({
          variables: {
            input: { ethAccount, message, signature },
          },
        });
        const { authenticate } = authData;
        const { user } = authenticate;

        return user;
      } catch (err) {
        disconnect();
        addErrorNotification({ content: err?.message, isCloseable: true });
      } finally {
        setLoading(false);
      }
    },
    [generateMessage, authenticateWithMessage, disconnect, addErrorNotification]
  );

  return { login, loading, isLoggedIn };
};

export default useAuth;
