import { useMutation } from "@apollo/client";

import { CREATE_EVENT } from "api/graphql/mutations/event";
import { useNotificationContext } from "context/notificationContext";

const useCreateEvent = () => {
  const [create, { data, loading, error }] = useMutation(CREATE_EVENT);
  const { addErrorNotification } = useNotificationContext();

  const createEvent = async (eventData = {}) => {
    try {
      const { title, description, monitorsCount, startsAt, endsAt } = eventData;

      const createdEvent = await create({
        variables: {
          input: { title, description, monitorsCount, startsAt, endsAt },
        },
      });

      return createdEvent?.data?.createEvent?.event;
    } catch (err) {
      addErrorNotification({ content: err?.message, isCloseable: true });
    }
  };

  return {
    createEvent,
    createdEvent: data?.createEvent?.event,
    createLoading: loading,
    createError: error,
  };
};

export default useCreateEvent;
