import { useParams } from "react-router-dom";

import { ReactComponent as TickIcon } from "shared/icons/tick.svg";
import { SecondaryLinkAsButton } from "shared/ui/links";

import { IconWrapper, Wrapper, Text } from "./styled";

const DoneSection = ({ text }) => {
  const { eventId } = useParams();

  return (
    <Wrapper>
      <IconWrapper>
        <TickIcon />
      </IconWrapper>

      <h1>Congratulations!</h1>
      {text && <Text>{text}</Text>}

      <SecondaryLinkAsButton href={`/event/${eventId}/gallery`}>
        Done
      </SecondaryLinkAsButton>
    </Wrapper>
  );
};

export default DoneSection;
