import { H2 } from "shared/styles/headersStyles";
import styled from "styled-components";

export const ListWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const Title = styled.h1`
  ${H2}
`;
