import { Autoplay, A11y, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/a11y";
import "swiper/css/effect-fade";

import { CenteringLayout } from "components/layouts/shared";
import NftImage from "components/guest/NftImage";
import { SLIDESHOW_DELAY } from "shared/constants/slideshow";

import { SliderWrapper } from "./styled";

const NftGallery = ({ nftList }) => {
  if (!nftList?.length) {
    return <CenteringLayout>Gallery is empty for now</CenteringLayout>;
  }

  return (
    <SliderWrapper>
      <Swiper
        loop
        modules={[Autoplay, A11y, EffectFade]}
        effect="fade"
        centeredSlides
        autoplay={{ delay: SLIDESHOW_DELAY, disableOnInteraction: false }}
      >
        {nftList.map((nft) => (
          <SwiperSlide key={nft.id}>
            <NftImage nft={nft} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderWrapper>
  );
};

export default NftGallery;
