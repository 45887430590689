import { StyledTextButton } from "./styled";

const TextButton = ({
  isUnderline,
  customStyles,
  additionalClasses,
  children,
  ...restProps
}) => {
  return (
    <StyledTextButton
      type="button"
      isUnderline={isUnderline}
      customStyles={customStyles}
      className={additionalClasses}
      {...restProps}
    >
      {children}
    </StyledTextButton>
  );
};

export default TextButton;
