import { useCallback, useMemo, useState } from "react";
import NotificationContext from "./NotificationContext";

const NotificationProvider = ({ children }) => {
  const [notificationList, setNotificationList] = useState([]);

  /**
    @param data = {
      content: string, type: string, isCloseable: boolean,
    }
  */
  const addNotification = useCallback((data) => {
    const id = Date.now();
    const item = { id, ...data };

    setNotificationList((prev) => {
      const isMessageExist = prev.some(
        ({ content }) => content === item.content
      );

      return isMessageExist ? prev : [...prev, item];
    });
  }, []);

  const addErrorNotification = useCallback(
    (data) => {
      addNotification({ ...data, type: "danger" });
    },
    [addNotification]
  );

  const removeNotification = useCallback((id) => {
    setNotificationList((prev) =>
      prev.filter(({ id: itemId }) => +itemId !== +id)
    );
  }, []);

  const memoizedData = useMemo(
    () => ({
      notificationList,
      addNotification,
      addErrorNotification,
      removeNotification,
    }),
    [
      notificationList,
      addNotification,
      addErrorNotification,
      removeNotification,
    ]
  );

  return (
    <NotificationContext.Provider value={memoizedData}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
