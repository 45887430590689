import Spinner from "../Spinner";
import { LoaderWrapper, LoaderText } from "./styled";

const Loader = ({
  text = "Loading...",
  additionalClasses,
  isSecondary,
  ...restProps
}) => {
  return (
    <LoaderWrapper isSecondary={isSecondary} {...restProps}>
      <Spinner isSecondary={isSecondary} />
      {text && <LoaderText>{text}</LoaderText>}
    </LoaderWrapper>
  );
};

export default Loader;
