import getClassNames from "shared/utils/getClassNames";
import Spinner from "shared/ui/Spinner";

import BaseButton from "../BaseButton";
import { buttonStyles } from "./styled";

const Button = ({
  type = "button",
  disabled,
  loading,
  onClick,
  color,
  additionalClasses,
  isRounded,
  isOutline,
  isSmall,
  children,
  ...restProps
}) => {
  const classes = getClassNames([
    color && `btn btn-${color}`,
    additionalClasses && additionalClasses,
    isRounded && "rounded-pill",
  ]);

  const ButtonTag = color ? "button" : BaseButton;
  const propertiesForCustomBtn = color
    ? {}
    : {
        customStyles: (theme) =>
          buttonStyles({ theme, isOutline, isRounded, isSmall }),
      };

  return (
    <ButtonTag
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={classes}
      {...propertiesForCustomBtn}
      {...restProps}
    >
      {loading ? (
        <>
          <Spinner isSmall isWhite /> Loading
        </>
      ) : (
        children
      )}
    </ButtonTag>
  );
};

export default Button;
