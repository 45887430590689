import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${colors.black};
    color: ${colors.yellow};
  `
);

export const Main = styled.main(
  ({ theme: { down, breakpoints, layoutYPadding, fonts }, isLoginPage }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
    text-align: center;
    ${!isLoginPage &&
    css`
      padding: ${layoutYPadding};

      ${down(breakpoints.sm)} {
        padding: ${layoutYPadding} 0.9rem;
      }
    `};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${fonts.secondary};
    }
  `
);
