import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "services/localStorageService";
import {
  ACCESS_TOKEN,
  EXPIRED_TOKEN_EVENT,
  IS_WALLET_CONNECTED,
} from "shared/constants/auth";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = getStorageData(ACCESS_TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError((errors) => {
  const { graphQLErrors, networkError } = errors;

  if (graphQLErrors)
    // eslint-disable-next-line array-callback-return
    graphQLErrors.some((error) => {
      const { message, locations, path } = error;

      console.log(
        `[GraphQL Error]: message: ${message}, locations: ${locations}, path: ${path}`
      );

      if (message.includes("Invalid credentials")) {
        // for disconnect
        const expiredTokenEvent = new CustomEvent(EXPIRED_TOKEN_EVENT, {
          detail: { description: "Token has expired" },
        });
        setStorageData(IS_WALLET_CONNECTED, false);
        removeStorageData(ACCESS_TOKEN);
        window.dispatchEvent(expiredTokenEvent);
      }
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: errorLink.concat(authLink.concat(httpLink)),
});

export default apolloClient;
