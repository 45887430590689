import styled, { css } from "styled-components";

export const StyledSpinner = styled.span(
  ({ theme: { colors }, isSmall, isWhite, isSecondary }) => {
    const { white, yellow, primary } = colors || {};
    let color = primary;
    if (isWhite) color = white;
    if (isSecondary) color = yellow;

    return css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${isSmall ? "1rem" : "2rem"};
      height: ${isSmall ? "1rem" : "2rem"};
      color: ${color};
      border-radius: 50%;
      background-color: transparent;
      border: ${isSmall ? "0.13rem" : "0.25rem"} solid currentcolor;
      border-right-color: transparent;
      animation: spinner infinite linear 0.75s;

      @keyframes spinner {
        100% {
          transform: rotate(360deg);
        }
      }
    `;
  }
);
