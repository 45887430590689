import { Navigate, Outlet } from "react-router-dom";

import useAuth from "api/hooks/useAuth";
import Header from "components/admin/Header";

import { Wrapper, Main } from "./styled";

const DefaultLayout = () => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn()) return <Navigate to="/admin/login" />;

  return (
    <Wrapper>
      <Header />

      <Main>
        <Outlet />
      </Main>
    </Wrapper>
  );
};

export default DefaultLayout;
