import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import routes from "routes";
import { useWalletConnection } from "context/walletConnectionContext";
import Notifier from "components/shared/Notifier";
import { EXPIRED_TOKEN_EVENT } from "shared/constants/auth";

const router = createBrowserRouter(routes);

function App() {
  const { account, disconnect, library } = useWalletConnection();

  // Disconnect if the account has been changed
  useEffect(() => {
    const { provider } = library || {};
    const handleAccountsChanged = ([newAddress]) => {
      if (newAddress !== account) disconnect();
    };
    provider?.on("accountsChanged", handleAccountsChanged);

    return () => {
      provider?.removeListener("accountsChanged", handleAccountsChanged);
    };
  }, [library, account, disconnect]);

  // Disconnect if token has expired
  useEffect(() => {
    const handleExpiredToken = () => {
      disconnect();
    };
    window.addEventListener(EXPIRED_TOKEN_EVENT, handleExpiredToken);

    return () => {
      window.removeEventListener(EXPIRED_TOKEN_EVENT, handleExpiredToken);
    };
  }, [disconnect]);

  return (
    <>
      <Notifier />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
