import Dropdown from "shared/ui/Dropdown";
import { DropdownItem, StyledLink } from "./styled";

const EventsLinksDropdown = () => {
  return (
    <Dropdown withArrow isActionLink actionText="Events">
      <DropdownItem>
        <StyledLink to="events/active">Active events</StyledLink>
      </DropdownItem>
      <DropdownItem>
        <StyledLink to="events/archive">Archived events</StyledLink>
      </DropdownItem>
    </Dropdown>
  );
};

export default EventsLinksDropdown;
