import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import NftList from "components/shared/NftList";
import { Button } from "shared/ui/buttons";
import Loader from "shared/ui/Loader";

import useGetEventById from "api/hooks/event/useGetEventById";
import { Footer, Title } from "./styled";

// TODO: change nfts or use another component for nft list
const Queue = () => {
  const { currentEvent, nfts, loading } = useGetEventById();
  const { title } = currentEvent || {};

  return (
    <>
      {title && <Title>{title}</Title>}

      {loading ? <Loader /> : <NftList isSelectable nfts={nfts} />}

      <Footer>
        <Button onClick={() => {}}>Save Queue</Button>
      </Footer>
    </>
  );
};

export default Queue;
