import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import EVENT from "api/graphql/queries/event";
import { useNotificationContext } from "context/notificationContext";

const useGetEventById = (interval) => {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(!!eventId);
  const [currentEvent, setCurrentEvent] = useState(null);
  const { addErrorNotification } = useNotificationContext();

  const [getEventById, { error }] = useLazyQuery(EVENT, {
    onCompleted: ({ event }) => {
      setCurrentEvent(event);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const variables = { id: +eventId };
        const options = interval
          ? { variables, pollInterval: interval }
          : { variables };
        await getEventById(options);
      } catch (err) {
        addErrorNotification({ content: err?.message, isCloseable: true });
      }
    };

    if (eventId) fetchEvent();
  }, [eventId, getEventById, interval, addErrorNotification]);

  return {
    currentEvent,
    nfts: currentEvent?.queues?.nodes?.[0]?.nfts?.nodes,
    loading,
    error,
  };
};

export default useGetEventById;
