import { forwardRef } from "react";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";

const QRCode = ({ id, value, size, isSvg, ...restProps }, ref) => {
  const QrCodeTag = isSvg ? QRCodeSVG : QRCodeCanvas;

  return (
    <QrCodeTag
      ref={ref} // doesn't work (package has issue with ref)
      id={id || "qr-code"}
      size={size || 300}
      level="H"
      value={value}
      includeMargin
      {...restProps}
    />
  );
};

export default forwardRef(QRCode);
