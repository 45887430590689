import { gql } from "@apollo/client";
import authRuleFragment from "../fragments/authRuleFragment";

export default gql`
  query me {
    me {
      ethAccount
      canCreateEvent {
        ...authRule
      }
    }
  }
  ${authRuleFragment}
`;
