import { useEffect, useState } from "react";
import { StyledImage } from "./styled";

const Image = ({
  src,
  placeholder,
  width = "100%",
  alt,
  customStyles,
  ...restProps
}) => {
  const [imagePath, setImagePath] = useState(placeholder);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = document.createElement("img");

    img.src = src;
    img.onload = () => {
      setImagePath(src);
      setLoading(false);
    };
    img.onerror = () => setLoading(false);
  }, [src]);

  return (
    <StyledImage
      src={imagePath}
      width={width}
      alt={alt}
      loading={loading ? 1 : 0}
      customStyles={customStyles}
      {...restProps}
    />
  );
};

export default Image;
