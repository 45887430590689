import { Navigate, useParams } from "react-router-dom";

import useAuth from "api/hooks/useAuth";
import { WithLogoLayout } from "components/layouts/guest";
import ConnectAndLogin from "components/shared/ConnectAndLogin";

import WelcomeSection from "components/guest/WelcomeSection";
import { Main } from "./styled";

const Login = () => {
  const { eventId } = useParams();
  const eventPath = `/event/${eventId}`;
  const { isLoggedIn } = useAuth();

  if (isLoggedIn()) return <Navigate replace to={eventPath} />;

  return (
    <Main>
      <WithLogoLayout>
        <WelcomeSection
          title="Welcome to&nbsp;Abracadabra NFT&nbsp;EXHIBIT!"
          question="Do you own NFTs?"
          action={<ConnectAndLogin successPath={eventPath} />}
        />
      </WithLogoLayout>
    </Main>
  );
};

export default Login;
