import { Question, Section } from "./styled";

const WelcomeSection = ({ title, question, action }) => {
  return (
    <>
      {title && <h1>{title}</h1>}

      {(question || action) && (
        <Section>
          {question && <Question>{question}</Question>}
          {action && action}
        </Section>
      )}
    </>
  );
};

export default WelcomeSection;
