import styled from "styled-components";

export const LogoWrapper = styled.figure`
  position: relative;
  max-width: 16rem;
  margin: 0;
`;

export const LogoOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme: { colors } }) => colors.yellow};
  mix-blend-mode: color-burn;
`;

export const Logo = styled.img`
  width: 100%;
`;
