import { css } from "styled-components";

// TODO: use in buttons
export const baseButtonStyles = ({ theme, customStyles }) => {
  const { colors, borderRadius } = theme;

  return css`
    --action-bg: ${colors.primary};
    --action-color: ${colors.white};
    --action-hover-bg: ${colors.primaryDark};

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.75rem;
    color: var(--action-color);
    cursor: pointer;
    user-select: none;
    border: 0.063rem solid var(--action-bg);
    border-radius: ${borderRadius};
    background-color: var(--action-bg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:focus,
    &:hover {
      background-color: var(--action-hover-bg);

      &:focus {
        outline: none;
        box-shadow: 0 0 0 0.25rem rgba(0 0 0 / 15%);
      }
    }

    ${customStyles && customStyles(theme)}
  `;
};

export const buttonStyles = ({
  theme: { colors },
  isOutline,
  isRounded,
  isSmall,
}) => css`
  ${isSmall && "padding: 0.25rem 0.5rem"};
  ${isRounded && "border-radius: 50rem"};
  ${isOutline &&
  css`
    color: var(--action-bg);
    background-color: ${colors.white};

    &:focus,
    &:hover {
      color: ${colors.white};
      background-color: var(--action-bg);
    }
  `}
`;

export const secondaryButtonStyles = ({
  theme: { colors, down, breakpoints },
  isOutline,
}) => css`
  --action-bg: ${isOutline ? "transparent" : colors.yellow};
  --action-color: ${isOutline ? colors.yellow : colors.black};
  --action-hover-bg: ${isOutline ? colors.yellow : colors.yellowDark};

  border-radius: 50rem;
  ${isOutline &&
  css`
    border-width: 0.18rem;
    border-color: ${colors.yellow};
    border-radius: 50rem;

    &:focus,
    &:hover {
      color: ${colors.black};
      background-color: ${colors.yellow};
    }
  `}

  ${down(breakpoints.sm)} {
    width: 100%;
  }
`;
