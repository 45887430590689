import { useQuery } from "@apollo/client";
import currentUserInfo from "api/graphql/queries/currentUserInfo";

const useGetUserInfo = () => {
  const { data, loading, error } = useQuery(currentUserInfo, {
    fetchPolicy: "cache-and-network",
  });

  return {
    ethAccount: data?.me?.ethAccount,
    canCreateEvent: data?.me?.canCreateEvent?.value,
    loading,
    error,
  };
};

export default useGetUserInfo;
