import { StyledSpinner } from "./styled";

const Spinner = ({ isSmall, isWhite, isSecondary, ...props }) => {
  return (
    <StyledSpinner
      role="status"
      isWhite={isWhite}
      isSmall={isSmall}
      isSecondary={isSecondary}
      {...props}
    />
  );
};

export default Spinner;
