import Spinner from "shared/ui/Spinner";

import { StyledButton } from "./styled";

const BaseButton = ({
  type = "button",
  disabled,
  loading,
  onClick,
  customStyles,
  children,
  ...restProps
}) => {
  return (
    <StyledButton
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      customStyles={customStyles}
      {...restProps}
    >
      {loading ? (
        <>
          <Spinner isSmall isWhite /> Loading
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default BaseButton;
