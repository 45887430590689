import styled, { css } from "styled-components";

export const StyledTextButton = styled.button(
  ({ theme, isUnderline, customStyles }) => css`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    transition: all 0.4s;
    cursor: pointer;
    color: inherit;
    ${isUnderline &&
    css`
      text-decoration: underline;
    `}

    &:hover {
      text-decoration: ${isUnderline ? "none" : "underline"};
    }

    ${customStyles && customStyles(theme)}
  `
);
