import styled, { css } from "styled-components";

export const LoaderWrapper = styled.div(
  ({ theme: { colors, zIndex }, isSecondary }) => css`
    position: absolute;
    inset: 0;
    z-index: ${zIndex.aboveContent};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white50};
    color: ${isSecondary ? colors.yellow : colors.primary};
  `
);

export const LoaderText = styled.strong`
  margin-left: 0.9rem;
  color: inherit;
  text-transform: uppercase;
`;
