import { getStorageData } from "services/localStorageService";
import { ACCESS_TOKEN } from "shared/constants/auth";

import { WithLogoLayout } from "components/layouts/guest";
import WelcomeSection from "components/guest/WelcomeSection";
import { SecondaryLinkAsButton } from "shared/ui/links";

import { Main, Section, Separator, Text } from "./styled";

const MainPage = () => {
  const isLoggedIn = getStorageData(ACCESS_TOKEN);
  const path = isLoggedIn ? "/admin" : "/admin/login";

  const action = (
    <>
      <SecondaryLinkAsButton href={path} isOutline>
        Go to the admin dashboard
      </SecondaryLinkAsButton>

      <Section>
        <Separator>or</Separator>
        <h2>Do you own NFTs?</h2>
        <Text>Find QR Code to Join Event!</Text>
      </Section>
    </>
  );

  return (
    <Main>
      <WithLogoLayout>
        <WelcomeSection
          title="Welcome to&nbsp;Abracadabra!"
          question="Are you an event administrator?"
          action={action}
        />
      </WithLogoLayout>
    </Main>
  );
};

export default MainPage;
