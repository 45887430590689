import { gql } from "@apollo/client";
import nftInfoFragment from "./nftInfoFragment";

export default gql`
  fragment nfts on NftTypeConnection {
    edges {
      cursor
      node {
        ...nftInfo
      }
    }

    nodes {
      ...nftInfo
    }

    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
  ${nftInfoFragment}
`;
