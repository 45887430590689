import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getParsedStorageData,
  setStorageData,
} from "services/localStorageService";
import { SELECTED_NFTS } from "shared/constants/localStorageKeys";

import { SecondaryButton } from "shared/ui/buttons";
import NftList from "components/shared/NftList";

import { Wrapper, Title } from "./styled";

const Event = ({ userNfts, eventData }) => {
  const { nodes: eventNfts } = eventData?.queues?.nodes?.[0]?.nfts || {};
  const { nfts = [], urls = [] } = getParsedStorageData(SELECTED_NFTS) || {};

  const filteredUserNfts = userNfts?.filter((nft) => {
    const {
      metadata: { image },
    } = nft;
    const alreadySaved = eventNfts?.find(({ cid }) => cid === image);

    if (image !== alreadySaved?.cid) return nft;
    return false;
  });

  const [selectedNfts, setSelectedNfts] = useState(nfts || []);
  const [selectedNftUrls, setSelectedNftUrls] = useState(urls || []);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onChangeNftList = (url, isSelected) => {
    if (isSelected) {
      const selectedNft = userNfts.find(
        ({ metadata: { image } }) => image === url
      );

      setSelectedNfts((prev) => [...prev, selectedNft]);
      setSelectedNftUrls((prev) => [...prev, url]);
    } else {
      const updatedNftUrls = selectedNftUrls.filter(
        (savedUrl) => savedUrl !== url
      );
      const updatedNfts = selectedNfts.filter(
        ({ metadata: { image } }) => image !== url
      );

      setSelectedNftUrls(updatedNftUrls);
      setSelectedNfts(updatedNfts);
    }
  };

  const onContinue = () => {
    setStorageData(SELECTED_NFTS, {
      nfts: selectedNfts,
      urls: selectedNftUrls,
    });
    navigate(`${pathname}/confirm`);
  };

  return (
    <Wrapper>
      <Title>Select up to 3 NFT&apos;s to show on displays</Title>
      <NftList
        isSelectable
        limit={3}
        nfts={filteredUserNfts}
        selectedNftUrls={selectedNftUrls}
        addRemoveNft={onChangeNftList}
      />

      {selectedNfts?.length > 0 && (
        <SecondaryButton onClick={onContinue}>Continue</SecondaryButton>
      )}
    </Wrapper>
  );
};

export default Event;
