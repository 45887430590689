import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem auto 0;
  gap: 1rem;
`;

export const PageNumber = styled.span(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    padding: 0.25rem 0.7rem;
    color: ${colors.primary};
    font-weight: bold;
  `
);
