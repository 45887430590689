import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.9rem;
  flex-grow: 1;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  color: ${({ theme: { colors } }) => colors.yellow};
  border-radius: 50%;
  border: 0.5rem solid currentcolor;
  width: 9rem;
  height: 9rem;

  svg {
    width: 50%;
    fill: currentcolor;
  }
`;

export const Text = styled.p`
  font-size: 1.5rem;
`;
