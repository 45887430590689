import { useState } from "react";

import { InputField, Select, TextareaField } from "shared/ui/formElements";
import DateField from "shared/ui/formElements/DateField";
import { SLIDESHOW_QUEUES } from "shared/constants/slideshow";

import useGetGalleries from "./useGetGalleries";
import useSaveEvent from "./useSaveEvent";
// import SlideshowButtons from "./SlideshowButtons";
import Actions from "./Actions";
import { FormSection, FieldWrapper, DatesLabel } from "./styled";

const EventForm = ({ currentEvent }) => {
  const galleries = useGetGalleries();
  const defaultGallery = galleries[0].value;
  const isEventArchived = currentEvent?.archived || false;

  const initialState = {
    id: currentEvent?.id || undefined,
    title: currentEvent?.title || "",
    description: currentEvent?.description || "",
    monitorsCount: currentEvent?.queues?.nodes?.[0]?.monitorsCount || 1,
    startsAt: currentEvent?.startsAt ? new Date(currentEvent?.startsAt) : null,
    endsAt: currentEvent?.endsAt ? new Date(currentEvent?.endsAt) : null,
    canArchive: currentEvent?.canArchive || { value: false },
  };

  const [eventData, setEventData] = useState(initialState);
  const { title, description, monitorsCount, startsAt, endsAt } = eventData;

  const [gallery, setGallery] = useState(defaultGallery);
  const defaultGallerySelected = gallery === defaultGallery;
  const [isDefaultGallerySelected, setIsDefaultGallerySelected] = useState(
    defaultGallerySelected
  );
  const [slideshowQueue, setSlideshowQueue] = useState(
    SLIDESHOW_QUEUES[0].value
  );
  const { saveEvent, loading } = useSaveEvent({
    eventId: currentEvent?.id,
    isDefaultGallerySelected,
  });

  const onSubmit = (event) => {
    event.preventDefault();
    const updatedData = {
      ...eventData,
      gallery,
      slideshowQueue: isDefaultGallerySelected && slideshowQueue,
    };

    saveEvent(updatedData);
  };

  const onGalleryChange = ({ target }) => {
    const { value } = target;

    if (value === defaultGallery) {
      setIsDefaultGallerySelected(true);
    } else {
      setIsDefaultGallerySelected(false);
    }

    setGallery(value);
  };

  const onChange = ({ target }) => {
    const { id, value, type } = target;

    setEventData((prev) => ({
      ...prev,
      [id]: type === "number" ? +value : value,
    }));
  };

  const onDateChange = (date, fieldName) => {
    setEventData((prev) => ({ ...prev, [fieldName]: date }));
  };

  return (
    <FormSection>
      {currentEvent ? <h1>{title}</h1> : <h1>New Event</h1>}

      <form onSubmit={onSubmit}>
        <InputField
          required
          id="title"
          label="Name"
          value={title}
          placeholder="We will show it on welcome page"
          onChange={onChange}
        />

        <DatesLabel>Event Dates</DatesLabel>
        <FieldWrapper>
          <DateField
            id="startsAt"
            selectedDate={startsAt}
            placeholderText="From"
            onChange={(date) => onDateChange(date, "startsAt")}
          />
          <DateField
            id="endsAt"
            selectedDate={endsAt}
            placeholderText="To"
            onChange={(date) => onDateChange(date, "endsAt")}
          />
        </FieldWrapper>

        <TextareaField
          required
          id="description"
          label="Description"
          placeholder="Can be used as a short instruction on event welcome page"
          value={description}
          onChange={onChange}
        />

        <InputField
          required
          type="number"
          id="monitorsCount"
          label="Number of monitors"
          value={monitorsCount}
          onChange={onChange}
        />

        {/* {isDefaultGallerySelected && (
          <SlideshowButtons
            slideshowQueue={slideshowQueue}
            setSlideshowQueue={setSlideshowQueue}
          />
        )} */}

        <Select
          id="gallery"
          label="Default gallery"
          value={gallery}
          options={galleries}
          onChange={onGalleryChange}
          placeholder="Will be shown it if no one joins event"
        />

        {!isEventArchived && (
          <Actions
            eventData={eventData}
            isEventArchived={isEventArchived}
            isDefaultGallerySelected={isDefaultGallerySelected}
            loading={loading}
          />
        )}
      </form>
    </FormSection>
  );
};

export default EventForm;
