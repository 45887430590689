import styled, { css } from "styled-components";

export const Label = styled.label`
  display: flex;
  cursor: pointer;
`;

export const CustomCheckbox = styled.span(
  ({ theme: { colors }, isRounded }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0.94rem;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    border-radius: ${isRounded ? "50%" : "0.25rem"};
    background-color: ${colors.white};
    border: 0.06rem solid ${colors.grayAD};

    & + ${Label} {
      margin-left: 0.3rem;
    }
  `
);

export const CheckboxWrapper = styled.div(
  ({ theme: { colors, zIndex }, isPositionedByLabel, isYellow }) => css`
    --checkbox-tick-color: ${isYellow ? colors.black : colors.white};
    --checkbox-bg-color: ${isYellow ? colors.yellow : colors.primary};
    --checkbox-border-color: ${isYellow ? colors.black : colors.primary};
    --checkbox-border-width: ${isYellow ? "0.13rem" : "0.06rem"};
    --checkbox-size: ${isYellow ? "1.9rem" : "1rem"};
    --checkbox-tick-size: ${isYellow ? "1rem" : "0.6rem"};

    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    font-size: inherit;

    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }

    svg {
      width: var(--checkbox-tick-size);
      fill: var(--checkbox-tick-color);
    }

    ${isPositionedByLabel &&
    css`
      align-items: stretch;
      width: 100%;

      span {
        position: absolute;
        z-index: ${zIndex.contentMax};
      }

      input[type="checkbox"],
      span {
        bottom: 0.63rem;
        right: 0.63rem;
      }

      ${CustomCheckbox} + ${Label} {
        margin-left: 0;
      }

      ${Label} {
        width: 100%;
      }
    `}
  `
);

export const NativeCheckbox = styled.input(
  ({ theme: { colors, zIndex } }) => css`
    position: absolute;
    z-index: ${zIndex.contentMin};
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked + span {
      background: var(--checkbox-bg-color);
      border: var(--checkbox-border-width) solid var(--checkbox-border-color);
    }

    &:focus + span {
      box-shadow: 0 0 0 0.2rem ${colors.grayAD};
    }

    &:focus ~ label {
      text-decoration: underline;
    }
  `
);
