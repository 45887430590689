import { secondaryButtonStyles } from "shared/styles/buttonStyles";
import BaseLinkAsButton from "../BaseLinkAsButton";

const SecondaryLinkAsButton = ({ href, isOutline, ...restProps }) => {
  return (
    <BaseLinkAsButton
      href={href}
      {...restProps}
      customStyles={(theme) => secondaryButtonStyles({ theme, isOutline })}
    />
  );
};

export default SecondaryLinkAsButton;
