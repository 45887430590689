const TextareaField = ({ id, name, label, error, ...restProps }) => {
  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}

      <textarea
        name={name}
        rows={3}
        className="form-control"
        id={id}
        {...restProps}
      />
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
};

export default TextareaField;
