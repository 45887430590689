import { useCallback, useEffect, useState } from "react";

import { useWalletConnection } from "context/walletConnectionContext";
import { httpHost } from "services/httpService";

const useGetWalletNfts = () => {
  const { account } = useWalletConnection();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getNFTMetadata = async (item) => {
    const { contract, id } = item;
    const { tokenMetadata, tokenId } = id;
    const url = `/getNFTMetadata?contractAddress=${contract.address}&tokenId=${tokenId}&tokenType=${tokenMetadata.tokenType}`;

    try {
      const { data } = await httpHost.get(url);
      return data;
    } catch (err) {
      console.error("NTF metadata error:", err);
    }
  };

  const getNFTs = useCallback(async () => {
    try {
      const { data } = (await httpHost.get(`/getNFTs/?owner=${account}`)) || {};
      const { ownedNfts } = data || {};

      const nfts = await Promise.all(
        ownedNfts?.map((element) => getNFTMetadata(element))
      );

      setList(nfts);
    } catch (err) {
      setError(err.message);
      console.error("NTF error:", err);
    } finally {
      setLoading(false);
    }
  }, [account]);

  useEffect(() => {
    getNFTs();
  }, [getNFTs]);

  return {
    nfts: list,
    loading,
    error,
  };
};

export default useGetWalletNfts;
