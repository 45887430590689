import { gql } from "@apollo/client";

export default gql`
  fragment authRule on AuthorizationResult {
    message
    reasons {
      details
      fullMessages
    }
    value
  }
`;
