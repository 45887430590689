import styled, { css } from "styled-components";

export const StyledImage = styled.img(
  ({ theme, loading, customStyles }) => css`
    object-fit: cover;
    object-position: center;
    filter: ${loading ? "blur(0.25rem)" : "blur(0)"};
    transition: filter 0.2s linear;
    ${customStyles && customStyles(theme)}
  `
);
