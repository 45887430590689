import Wallet from "components/admin/Wallet";
import EventsLinksDropdown from "./EventsLinksDropdown";
import { StyledHeader, LogoLink, NavigationSection } from "./styled";

const Header = () => {
  return (
    <StyledHeader className="bg-light">
      <LogoLink to="/">Abracadabra</LogoLink>

      <NavigationSection>
        <EventsLinksDropdown />
        <Wallet />
      </NavigationSection>
    </StyledHeader>
  );
};

export default Header;
