import { useLocation } from "react-router-dom";

import { Checkbox } from "shared/ui/formElements";
import Image from "shared/ui/Image";

import { ImageOverlay, Item } from "./styled";

const NftItem = ({
  data,
  addRemoveNft,
  isSelectable,
  selectedImages,
  selectImage,
  unSelectImage,
}) => {
  const { pathname } = useLocation();
  const isGuestPage = !pathname.includes("admin");

  const { metadata, description } = data || {};
  const { image: itemUrl } = metadata || {};

  if (!itemUrl) return null;

  const isChecked = selectedImages.some((imageId) => imageId === itemUrl);

  const onChange = ({ target }) => {
    const { checked, id: inputId } = target;

    if (checked) {
      selectImage(inputId);
    } else {
      unSelectImage(inputId);
    }

    if (addRemoveNft) addRemoveNft(inputId, checked);
  };

  const image = (
    <Image
      alt={description}
      src={itemUrl}
      placeholder="/images/placeholder.jpg"
    />
  );

  return (
    <Item>
      {isSelectable ? (
        <Checkbox
          key={itemUrl}
          id={itemUrl}
          value={itemUrl}
          onChange={onChange}
          checked={isChecked}
          isPositionedByLabel
          isYellow={isGuestPage}
          isRounded={isGuestPage}
        >
          {isChecked && <ImageOverlay />}
          {image}
        </Checkbox>
      ) : (
        image
      )}
    </Item>
  );
};

export default NftItem;
