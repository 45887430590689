import { H1 } from "shared/styles/headersStyles";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Title = styled.h1(
  ({ theme: { colors } }) => css`
    color: ${colors.white};
  `
);

export const StyledLink = styled.a(
  ({ theme: { colors, fonts } }) => css`
    ${H1};
    color: ${colors.yellow};
    font-family: ${fonts.secondary};

    &:hover {
      color: ${colors.yellow};
      text-decoration: none;
    }
  `
);
