import { DefaultLayout } from "components/layouts/guest";
import { Login, EventGallery, GuestEvent, ConfirmNfts } from "pages/guest";

export default [
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "event/:eventId",
        element: <GuestEvent />,
      },
      {
        path: "event/:eventId/gallery",
        element: <EventGallery />,
      },
      {
        path: "event/:eventId/confirm",
        element: <ConfirmNfts />,
      },
      {
        path: "*",
        element: <p>There&apos;s nothing here: 404!</p>,
      },
    ],
  },
  {
    path: "/event/:eventId/login",
    element: <Login />,
  },
];
