import { useQuery } from "@apollo/client";
import currentUserEvents from "api/graphql/queries/currentUserEvents";

const LIMIT = 10;

const useGetEventsList = ({
  isArchivedEvents = false,
  beforeCursor,
  afterCursor,
}) => {
  const first =
    afterCursor || (!afterCursor && !beforeCursor) ? LIMIT : undefined;
  const last = beforeCursor ? LIMIT : undefined;

  const { data, loading, error } = useQuery(currentUserEvents, {
    fetchPolicy: "cache-and-network",
    variables: {
      archived: isArchivedEvents,
      first,
      last,
      after: afterCursor,
      before: beforeCursor,
    },
  });

  return {
    events: data?.me?.events?.edges || null,
    cursor: data?.me?.events?.edges?.cursor,
    pageInfo: data?.me?.events?.pageInfo,
    loading,
    error,
  };
};

export default useGetEventsList;
