import { WithLogoLayout } from "components/layouts/guest";
import { formattedISODate } from "services/dateService";
import { Wrapper, Title, StyledLink } from "./styled";

const EventEnded = ({ date }) => {
  const endDate = formattedISODate(date);

  return (
    <WithLogoLayout>
      <Wrapper>
        <Title>Abracadabra NFT EXHIBIT ended at {endDate}</Title>

        <StyledLink href="mailto:some.mail@here">
          Contact Us about upcoming events
        </StyledLink>
      </Wrapper>
    </WithLogoLayout>
  );
};

export default EventEnded;
