import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { ethers } from "ethers";
import { Web3ReactProvider } from "@web3-react/core";
import { ThemeProvider } from "styled-components";

import ApolloClient from "api/ApolloClient";
import { GlobalStyles, theme } from "shared/styles";
import { WalletConnectionProvider } from "context/walletConnectionContext";
import { NotificationProvider } from "context/notificationContext";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
const getLibrary = (provider) => new ethers.providers.Web3Provider(provider);

root.render(
  <StrictMode>
    <ApolloProvider client={ApolloClient}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />

          <WalletConnectionProvider>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </WalletConnectionProvider>
        </ThemeProvider>
      </Web3ReactProvider>
    </ApolloProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
