import { H1 } from "shared/styles/headersStyles";
import styled, { css } from "styled-components";

export const Title = styled.h1(
  ({ theme: { colors } }) => css`
    color: ${colors.white};
  `
);

export const TitleSpan = styled.span`
  display: flex;
  margin-top: 2rem;
`;

export const SubTitle = styled.h2(
  ({ theme: { colors } }) => css`
    ${H1};
    color: ${colors.white};
  `
);
