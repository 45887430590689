import styled, { css } from "styled-components";
import { H2 } from "shared/styles/headersStyles";

export const Title = styled.h1`
  ${H2}
`;

export const Footer = styled.footer(
  ({ theme: { colors } }) => css`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem 1.9rem;
    background-color: ${colors.grayF8};
  `
);
