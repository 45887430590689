import styled from "styled-components";

export const LoadingSection = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 0.63rem;
  }
`;
