import { H3 } from "shared/styles/headersStyles";
import styled, { css } from "styled-components";

export const Card = styled.section(
  ({ theme: { colors, borderRadius, down, breakpoints } }) => css`
    padding: 1.25rem 0.8rem;
    background-color: ${colors.grayF8};
    border-radius: ${borderRadius};

    &:not(:first-child) {
      margin-top: 0.63rem;
    }

    ${down(breakpoints.xs)} {
      padding: 1.25rem 0.5rem;
    }
  `
);

export const Title = styled.h2`
  ${H3}
`;

export const Actions = styled.section`
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
`;

export const dropdownItemStyles = () => css`
  width: 100%;
  padding: 0.5rem 1.56rem;

  &:first-child {
    padding-top: 1rem;
  }

  &:last-child {
    padding-bottom: 1rem;
  }
`;

export const ModalContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0.5rem;
`;
