import MainPage from "pages/MainPage";
import adminRoutes from "./adminRoutes";
import guestRoutes from "./guestRoutes";

export default [
  {
    path: "/",
    element: <MainPage />,
  },
  ...adminRoutes,
  ...guestRoutes,
];
