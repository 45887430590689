import useGetEventById from "api/hooks/event/useGetEventById";
import NftGallery from "components/guest/NftGallery";
import Loader from "shared/ui/Loader";

const Event = () => {
  const interval = 60000;
  const { currentEvent, loading } = useGetEventById(interval);
  const { queues } = currentEvent || {};
  const { nfts } = queues?.nodes?.[0] || {};
  const { nodes: savedNfts } = nfts || {};

  if (loading) return <Loader isSecondary />;

  return <NftGallery nftList={savedNfts} />;
};

export default Event;
