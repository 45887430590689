import BaseButton from "../BaseButton";
import { buttonStyles } from "./styled";

const SecondaryButton = ({ isOutline, ...props }) => {
  return (
    <BaseButton
      customStyles={(theme) => buttonStyles({ theme, isOutline })}
      {...props}
    />
  );
};

export default SecondaryButton;
