import styled from "styled-components";

export const List = styled.div`
  width: 100%;
  max-width: 37.5rem;
`;

export const Message = styled.p`
  width: 100%;
  text-align: center;
`;
