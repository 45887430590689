import { useCallback, useEffect } from "react";

import Portal from "../Portal";
import { CloseButton } from "../buttons";
import { Overlay, Wrapper, Title, CloseButtonAsLink } from "./styled";

const ModalWindow = ({ isOpen, close, headerTitle, children }) => {
  const closeWindow = useCallback((e) => close(e), [close]);

  useEffect(() => {
    const closeOnEscapeKey = (event) => {
      if (event.key === "Escape") closeWindow();
    };

    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => document.body.removeEventListener("keydown", closeOnEscapeKey);
  }, [closeWindow]);

  if (!isOpen) return null;

  return (
    <Portal wrapperId="modal-portal">
      <Overlay>
        <Wrapper>
          {headerTitle && (
            <div className="modal-header bg-light p-3">
              <Title>{headerTitle}</Title>

              <CloseButtonAsLink onClick={closeWindow} title="Закрыть (Esc)">
                Close
              </CloseButtonAsLink>
            </div>
          )}

          {!headerTitle && (
            <CloseButton
              className="btn-close"
              onClick={closeWindow}
              title="Закрыть (Esc)"
            />
          )}

          {children}
        </Wrapper>
      </Overlay>
    </Portal>
  );
};

export default ModalWindow;
