import useGetEventById from "api/hooks/event/useGetEventById";
import useGetWalletNfts from "shared/hooks/useGetWalletNfts";

import Loader from "shared/ui/Loader";
import Event from "components/guest/Event";
import NftNotFound from "components/guest/NftNotFound";
import EventEnded from "components/guest/EventEnded";

const GuestEvent = () => {
  const {
    currentEvent,
    loading: eventLoading,
    error: eventError,
  } = useGetEventById();
  const { nfts: userNfts, loading: nftLoading } = useGetWalletNfts();

  const { archived, endsAt } = currentEvent || {};
  const loading = eventLoading || nftLoading;
  const isDataLoaded = !eventError && !loading;

  if (loading) return <Loader isSecondary />;
  if (!eventLoading && eventError) return eventError.message;

  if (isDataLoaded && archived) return <EventEnded date={endsAt} />;
  if (isDataLoaded && !userNfts?.length) return <NftNotFound />;

  return <Event userNfts={userNfts} eventData={currentEvent} />;
};

export default GuestEvent;
