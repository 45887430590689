import { gql } from "@apollo/client";
import nftsFragment from "../fragments/nftsFragment";

export const ATTACH_NFTS = gql`
  mutation attachNfts(
    $input: InputsEventsAttachNftsInput!
    $nftAfter: String
    $nftBefore: String
    $nftFirst: Int
    $nftLast: Int
  ) {
    attachNfts(input: $input) {
      queue {
        id
        monitorsCount
        nfts(
          after: $nftAfter
          before: $nftBefore
          first: $nftFirst
          last: $nftLast
        ) {
          ...nfts
        }
      }
    }
  }
  ${nftsFragment}
`;
