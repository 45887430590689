import { useState } from "react";

import useGetEventsList from "api/hooks/event/useGetEventsList";
import EventsList from "components/admin/EventsList";
import Pagination from "components/admin/Pagination";
import Loader from "shared/ui/Loader";

import { ListWrapper, Title } from "./styled";

const Events = ({ isArchivedEvents }) => {
  const [beforeCursor, setBeforeCursor] = useState();
  const [afterCursor, setAfterCursor] = useState();

  const [pageNumber, setPageNumber] = useState(1);
  const { loading, events, pageInfo } = useGetEventsList({
    isArchivedEvents,
    beforeCursor,
    afterCursor,
  });
  const { hasNextPage, hasPreviousPage } = pageInfo || {};

  return (
    <>
      <Title>{isArchivedEvents ? "Archived" : "Active"} Events</Title>

      <ListWrapper>
        {loading ? <Loader /> : <EventsList list={events} />}
      </ListWrapper>

      {(hasNextPage || hasPreviousPage) && (
        <Pagination
          pageInfo={pageInfo}
          setBeforeCursor={setBeforeCursor}
          setAfterCursor={setAfterCursor}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      )}
    </>
  );
};

export default Events;
