import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  ({ theme: { colors, fonts, layoutYPadding } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    height: calc(100vh - ${layoutYPadding} * 2);
    padding: 4rem 2rem 1rem;
    background-color: ${colors.black};
    color: ${colors.yellow};
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${fonts.secondary};
    }
  `
);
