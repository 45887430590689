import styled, { css } from "styled-components";

export const DateInput = styled.div(
  ({ theme, customStyles }) => css`
    input {
      width: 100%;
      padding: 0.375rem 0.75rem;
      border: 0.063rem solid ${theme.colors.grayCE};
      border-radius: 0.375rem;
      appearance: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      font-size: 1rem;
      font-weight: 400;

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      }
    }

    ${customStyles && customStyles(theme)};
  `
);
