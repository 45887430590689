import { useNotificationContext } from "context/notificationContext";

const copyToClipboardPolyfill = (str) => {
  const textarea = document.createElement("textarea");

  textarea.style.position = "absolute";
  textarea.style.left = "-9999px";
  textarea.setAttribute("readonly", "");
  textarea.value = str;

  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
};

const useClipboard = () => {
  const { addErrorNotification, addNotification } = useNotificationContext();

  const copyToClipboard = async (string, withoutMessage = false) => {
    const { permissions, clipboard } = navigator || {};
    const { state: writeState } =
      (await permissions?.query({ name: "clipboard-write" })) || {};
    const isWriteGranted = writeState === "granted" || writeState === "prompt";

    if (isWriteGranted && clipboard?.writeText) {
      try {
        await clipboard.writeText(string);
        if (string && !withoutMessage) {
          addNotification({ content: "Data copied to clipboard" });
        }
        return;
      } catch (error) {
        addErrorNotification({ content: error?.message, isCloseable: true });
      }
    }

    copyToClipboardPolyfill(string);
  };

  return copyToClipboard;
};

export default useClipboard;
