import { useState } from "react";

import DoneSection from "components/guest/DoneSection";
import ConfirmSection from "components/guest/ConfirmSection";

const ConfirmNfts = () => {
  const [isDone, setIsDone] = useState(false);

  if (isDone) {
    return (
      <DoneSection text="Take a look on monitors, your NFT’s will appear soon!" />
    );
  }

  return <ConfirmSection setIsDone={setIsDone} />;
};

export default ConfirmNfts;
