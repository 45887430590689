import { StyledButton } from "./styled";

const CloseButton = ({ ...restProps }) => {
  return (
    <StyledButton
      type="button"
      className="btn-close"
      aria-label="Close"
      title="Close"
      {...restProps}
    />
  );
};

export default CloseButton;
