import { Navigate } from "react-router-dom";

import useAuth from "api/hooks/useAuth";
import { CenteringLayout } from "components/layouts/shared";
import ConnectAndLogin from "components/shared/ConnectAndLogin";

import { Wrapper, Logo } from "./styled";

const Login = () => {
  const { isLoggedIn } = useAuth();
  const successPath = "/admin";

  if (isLoggedIn()) return <Navigate replace to={successPath} />;

  return (
    <CenteringLayout>
      <Wrapper>
        <Logo
          alt="abracadabra blue logo"
          src="/images/abracadabraBlueLogo.png"
        />

        <ConnectAndLogin successPath={successPath} />
      </Wrapper>
    </CenteringLayout>
  );
};

export default Login;
