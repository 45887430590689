import { css } from "styled-components";

export const buttonStyles = ({
  theme: { colors },
  isOutline,
  isRounded,
  isSmall,
}) => css`
  ${isSmall && "padding: 0.25rem 0.5rem"};
  ${isRounded && "border-radius: 50rem"};
  ${isOutline &&
  css`
    color: var(--button-bg);
    background-color: ${colors.white};

    &:focus,
    &:hover {
      color: ${colors.white};
      background-color: var(--button-bg);
    }
  `}
`;
