import styled, { css } from "styled-components";
import { TextButton } from "../buttons";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255 / 85%);
`;

export const Wrapper = styled.div(
  ({ theme: { colors, borderRadius } }) => css`
    position: relative;
    max-width: 95%;
    max-height: 95vh;
    border: 0.06rem solid ${colors.grayAD};
    border-radius: ${borderRadius};
    box-shadow: 0 0 0.94rem rgb(0 0 0 / 25%);
    background-color: ${colors.white};
    overflow: hidden;
  `
);

export const Title = styled.h2`
  margin: 0 2rem 0 0;
`;

export const CloseButtonAsLink = styled(TextButton)(
  ({ theme: { colors } }) => css`
    color: ${colors.primary};
    font-weight: 600;
  `
);
