import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useWebShare from "shared/hooks/useWebShare";
import { Button } from "shared/ui/buttons";

import MonitorsDropdown from "./MonitorsDropdown";
import ActionsDropdown from "./ActionsDropdown";
import QrCodeModal from "./QrCodeModal";
import { Card, Title, Actions } from "./styled";

const EventCard = ({ event }) => {
  const { id, title, description, queues } = event || {};
  const { monitorsCount } = queues?.nodes?.[0] || {};
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const share = useWebShare();

  const shareEventGalleryUrl = () => {
    const eventUrl = `${window.location.origin}/event/${id}/gallery`;
    share({
      title,
      text: description,
      url: eventUrl,
      fallBackString: eventUrl,
    });
  };

  return (
    <Card>
      <Title>{title}</Title>

      <Actions>
        <Button isOutline onClick={() => navigate(`/admin/event/${id}/queue`)}>
          Manage Queue
        </Button>

        {monitorsCount === 1 ? (
          <Button isOutline onClick={shareEventGalleryUrl}>
            Monitor
          </Button>
        ) : (
          <MonitorsDropdown
            monitorsCount={monitorsCount}
            shareEventGalleryUrl={shareEventGalleryUrl}
          />
        )}

        <ActionsDropdown
          event={event}
          openModal={() => setIsModalVisible(true)}
        />
      </Actions>

      <QrCodeModal
        eventId={id}
        title={title}
        isOpen={isModalVisible}
        close={() => setIsModalVisible(false)}
      />
    </Card>
  );
};

export default EventCard;
