import { useMutation } from "@apollo/client";

import { ARCHIVE_EVENT } from "api/graphql/mutations/event";
import { useNotificationContext } from "context/notificationContext";

const useArchiveEvent = () => {
  const [archive, { loading, error }] = useMutation(ARCHIVE_EVENT);
  const { addErrorNotification } = useNotificationContext();

  const archiveEvent = async (id) => {
    try {
      await archive({ variables: { input: { id } } });
    } catch (err) {
      addErrorNotification({ content: err?.message, isCloseable: true });
    }
  };

  return {
    archiveEvent,
    archiveLoading: loading,
    archiveError: error,
  };
};

export default useArchiveEvent;
