import Dropdown from "shared/ui/Dropdown";
import { TextButton } from "shared/ui/buttons";

import { dropdownItemStyles } from "../styled";

const MonitorsDropdown = ({ monitorsCount, shareEventGalleryUrl }) => {
  const monitors = Array(monitorsCount)
    .fill("Monitor #")
    .map((el, index) => el + (index + 1));

  return (
    <Dropdown withArrow isActionOutlineBtn actionText="Monitors">
      {monitors.map((monitor) => (
        <TextButton
          key={monitor}
          onClick={shareEventGalleryUrl}
          customStyles={dropdownItemStyles}
        >
          {monitor}
        </TextButton>
      ))}
    </Dropdown>
  );
};

export default MonitorsDropdown;
