import { useRef, useState } from "react";

import useClickOutside from "shared/hooks/useClickOutside";

import {
  DropdownWrapper,
  ActionLink,
  ActionButton,
  DropdownMenu,
} from "./styled";

const Dropdown = ({
  children,
  actionText,
  isActionLink,
  isActionOutlineBtn,
  additionalClasses,
  withArrow,
}) => {
  const [isOpen, setIsOpen] = useState();
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setIsOpen(false));

  const Action = isActionLink ? ActionLink : ActionButton;

  const openDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <Action
        isOutline={isActionOutlineBtn}
        className={additionalClasses}
        isOpen={isOpen}
        withArrow={withArrow}
        onClick={openDropdown}
      >
        {actionText}
      </Action>

      {isOpen && <DropdownMenu>{children}</DropdownMenu>}
    </DropdownWrapper>
  );
};

export default Dropdown;
