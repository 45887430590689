import { useNotificationContext } from "context/notificationContext";
import useClipboard from "./useClipboard";

const useWebShare = () => {
  const { addErrorNotification, addNotification } = useNotificationContext();
  const copyToClipboard = useClipboard();

  const shareData = async ({
    url,
    text,
    title,
    files,
    onSuccess,
    onFail,
    onNotSupported,
    fallBackString,
  }) => {
    if (navigator.share) {
      try {
        const isFiles = files && navigator.canShare({ files });
        const data = isFiles ? { title, text, files } : { title, text, url };

        await navigator.share(data);
        if (onSuccess) onSuccess();
      } catch (error) {
        addErrorNotification({ content: error.message });
        if (onFail) onFail(error);
      }
    } else {
      if (fallBackString) copyToClipboard(fallBackString, true);

      const defaultMessage = "Your browser does not support Web Share API";
      const message = fallBackString
        ? `${defaultMessage}. Data copied to the clipboard`
        : defaultMessage;

      addNotification({ content: message, type: "primary" });
      if (onNotSupported) onNotSupported();
    }
  };

  return shareData;
};

export default useWebShare;
