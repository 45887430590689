import { useState } from "react";

import { useNotificationContext } from "context/notificationContext";
import NftItem from "./NftItem";
import { List } from "./styled";

const NftList = ({
  nfts = [],
  selectedNftUrls,
  addRemoveNft,
  isSelectable,
  limit,
}) => {
  const [selectedImages, setSelectedImages] = useState(selectedNftUrls || []);
  const { addNotification } = useNotificationContext();

  const selectImage = (imageUrl) => {
    if (selectedImages.length === limit) {
      addNotification({
        content: `You can only select ${limit} NFT`,
        type: "warning",
      });
      return;
    }
    setSelectedImages((prev) => [...prev, imageUrl]);
  };

  const unSelectImage = (imageUrl) => {
    const updatedList = selectedImages.filter(
      (selectedImage) => selectedImage !== imageUrl
    );
    setSelectedImages(updatedList);
  };

  return nfts.length > 0 ? (
    <List>
      {nfts.map((item, index) => (
        <NftItem
          key={index}
          data={item}
          addRemoveNft={addRemoveNft}
          isSelectable={isSelectable}
          selectImage={selectImage}
          unSelectImage={unSelectImage}
          selectedImages={selectedImages}
        />
      ))}
    </List>
  ) : (
    <p className="text-center">There are no NFTs here</p>
  );
};

export default NftList;
