import { WithLogoLayout } from "components/layouts/guest";
import { SubTitle, Title, TitleSpan } from "./styled";

const NftNotFound = () => {
  return (
    <WithLogoLayout>
      <Title>
        No NFT found in connected Wallet.
        <br />
        <TitleSpan>You can choose another account.</TitleSpan>
      </Title>

      <SubTitle>Do you own NFTs?</SubTitle>
    </WithLogoLayout>
  );
};

export default NftNotFound;
