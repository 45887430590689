import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  getParsedStorageData,
  removeStorageData,
  setStorageData,
} from "services/localStorageService";
import { ACCESS_TOKEN, IS_WALLET_CONNECTED } from "shared/constants/auth";

import WalletConnection from "./WalletConnectionContext";

const WalletConnectionProvider = ({ children }) => {
  const { account, active, library, activate, deactivate } = useWeb3React();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const injected = useMemo(
    () =>
      new InjectedConnector({
        supportedChainIds: [1, 5, 1337], // ETH Main, Goerli and local Hardhat
      }),
    []
  );

  const isConnected = useCallback(() => {
    return getParsedStorageData(IS_WALLET_CONNECTED);
  }, []);

  const connect = useCallback(
    async (successCallback) => {
      setLoading(true);
      try {
        await activate(injected);

        if (!isConnected()) setStorageData(IS_WALLET_CONNECTED, true);
        if (successCallback) successCallback();
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [activate, injected, isConnected]
  );

  const disconnect = useCallback(
    (successCallback) => {
      try {
        deactivate();
        setStorageData(IS_WALLET_CONNECTED, false);
        removeStorageData(ACCESS_TOKEN);
        if (successCallback) successCallback();
      } catch (err) {
        setError(err);
      }
    },
    [deactivate]
  );

  useEffect(() => {
    if (!account && isConnected()) {
      connect();
    }
  }, [connect, account, isConnected]);

  const memoizedValue = useMemo(
    () => ({
      error,
      loading,
      isConnected,
      account,
      active,
      library,
      disconnect,
      connect,
    }),
    [isConnected, error, loading, account, active, library, disconnect, connect]
  );

  return (
    <WalletConnection.Provider value={memoizedValue}>
      {children}
    </WalletConnection.Provider>
  );
};

export default WalletConnectionProvider;
