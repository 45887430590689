import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  ({ theme: { down, breakpoints } }) => css`
    position: fixed;
    top: 3.75rem;
    right: 0.63rem;
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: 0.63rem;
    min-width: 14.5rem;
    max-width: 18.75rem;
    max-height: calc(100vh - 1.26rem);
    overflow: auto;

    ${down(breakpoints)} {
      max-width: none;
      min-width: auto;
      width: calc(100vw - 1.26rem);
    }
  `
);
