import { Logo, LogoWrapper, LogoOverlay } from "./styled";

const LogoImage = () => {
  return (
    <LogoWrapper>
      <LogoOverlay />
      <Logo alt="abracadabra blue logo" src="/images/abracadabraBlueLogo.png" />
    </LogoWrapper>
  );
};

export default LogoImage;
