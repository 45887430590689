import { useContext } from "react";
import WalletConnectionContext from "./WalletConnectionContext";

const useWalletConnection = () => {
  const {
    active,
    account,
    library,
    error,
    loading,
    isConnected,
    disconnect,
    connect,
  } = useContext(WalletConnectionContext);

  return {
    active,
    account,
    library,
    error,
    loading,
    isConnected,
    disconnect,
    connect,
  };
};

export default useWalletConnection;
