import { DefaultLayout } from "components/layouts/admin";
import Queue from "components/admin/Queue";
import SelectableNfts from "components/admin/SelectableNfts";

import { Login, Welcome, Events, Event } from "pages/admin";

export default [
  {
    path: "admin",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Welcome />,
      },
      {
        path: "events/active",
        element: <Events />,
      },
      {
        path: "events/archive",
        element: <Events isArchivedEvents />,
      },
      {
        path: "event/new",
        element: <Event />,
      },
      {
        path: "event/:eventId",
        element: <Event />,
      },
      {
        path: "event/:eventId/select-nfts",
        element: <SelectableNfts />,
      },
      {
        path: "event/:eventId/queue",
        element: <Queue />,
      },
      {
        path: "*",
        element: <p>There&apos;s nothing here: 404!</p>,
      },
    ],
  },
  {
    path: "/admin/login",
    element: <Login />,
  },
];
