import { gql } from "@apollo/client";
import eventFragment from "./eventFragment";

export default gql`
  fragment allEventsInfo on EventTypeConnection {
    edges {
      cursor
      node {
        ...eventFragment
      }
    }
    nodes {
      ...eventFragment
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
  ${eventFragment}
`;
