import { useParams } from "react-router-dom";

import useGetEventById from "api/hooks/event/useGetEventById";
import useAttachNfts from "api/hooks/nft/useAttachNfts";
import {
  getParsedStorageData,
  removeStorageData,
} from "services/localStorageService";
import { SELECTED_NFTS } from "shared/constants/localStorageKeys";

import NftList from "components/shared/NftList";
import { SecondaryButton } from "shared/ui/buttons";
import { SecondaryLinkAsButton } from "shared/ui/links";

import { Wrapper, Actions } from "./styled";

const ConfirmSection = ({ setIsDone }) => {
  const { currentEvent, nfts: savedNfts } = useGetEventById();

  const { queues } = currentEvent || {};
  const { id: queueId } = queues?.nodes?.[0] || {};

  const { nfts, urls } = getParsedStorageData(SELECTED_NFTS) || {};
  const { eventId } = useParams();
  const { attachNfts } = useAttachNfts();

  const sendToEvent = () => {
    const cids = urls.filter((url) => {
      const alreadySaved = savedNfts.find(({ cid }) => cid === url);
      return url !== alreadySaved?.cid;
    });

    attachNfts({ queueId, cids });
    setIsDone(true);
    removeStorageData(SELECTED_NFTS);
  };

  return (
    <Wrapper>
      <h1>Confirm your Choice</h1>
      <NftList nfts={nfts} />

      <Actions>
        <SecondaryButton onClick={sendToEvent}>Send to Display</SecondaryButton>
        <SecondaryLinkAsButton isOutline href={`/event/${eventId}`}>
          Choose another NFT&apos;s
        </SecondaryLinkAsButton>
      </Actions>
    </Wrapper>
  );
};

export default ConfirmSection;
