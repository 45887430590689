import { Navigate, Outlet, useParams } from "react-router-dom";

import useAuth from "api/hooks/useAuth";
import { Wrapper, Main } from "./styled";

const DefaultLayout = () => {
  const { isLoggedIn } = useAuth();
  const { eventId } = useParams();

  if (!isLoggedIn()) {
    return <Navigate to={`/event/${eventId}/login`} />;
  }

  return (
    <Wrapper>
      <Main>
        <Outlet />
      </Main>
    </Wrapper>
  );
};

export default DefaultLayout;
