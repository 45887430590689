import { useCallback, useEffect, useState } from "react";
import { CloseButton } from "shared/ui/buttons";

const Notification = (props) => {
  const {
    id,
    content,
    onClose,
    isCloseable,
    type = "success",
    delay = 5000,
    removeFromList,
  } = props;
  const [isVisible, setIsVisible] = useState(content);

  const closeNotification = useCallback(() => {
    setIsVisible(false);

    if (removeFromList) removeFromList(id);
    if (onClose) onClose();
  }, [id, removeFromList, onClose]);

  useEffect(() => {
    let notifierTimer;

    if (!isCloseable) {
      notifierTimer = setTimeout(() => closeNotification(), delay);
    }

    return () => {
      if (notifierTimer) clearTimeout(notifierTimer);
    };
  }, [isCloseable, closeNotification, delay]);

  if (!isVisible) return null;

  return (
    <div
      className={`alert alert-${type} d-flex justify-content-between`}
      role="alert"
    >
      <p className="m-0 me-2">{content}</p>

      {isCloseable && <CloseButton onClick={closeNotification} />}
    </div>
  );
};

export default Notification;
