import { StyledLink } from "./styled";

const BaseLinkAsButton = ({ href, children, customStyles, ...restProps }) => {
  return (
    <StyledLink to={href} customStyles={customStyles} {...restProps}>
      {children}
    </StyledLink>
  );
};

export default BaseLinkAsButton;
