import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { baseButtonStyles } from "shared/styles/buttonStyles";

export const StyledLink = styled(Link)(
  ({ theme, customStyles }) => css`
    ${baseButtonStyles({ theme, customStyles })};
    text-decoration: none;

    &:focus,
    &:hover {
      color: initial;
      text-decoration: none;
    }

    &:focus-visible {
      outline: none;
    }
  `
);
