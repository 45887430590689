import { useMutation } from "@apollo/client";

import { ATTACH_NFTS } from "api/graphql/mutations/nft";
import { useNotificationContext } from "context/notificationContext";

const useAttachNfts = () => {
  const [attach, { loading, error }] = useMutation(ATTACH_NFTS);
  const { addErrorNotification } = useNotificationContext();

  const attachNfts = async ({
    queueId,
    cids = [],
    after,
    before,
    first,
    last,
  }) => {
    try {
      const queue = await attach({
        variables: {
          input: {
            queueId,
            cids,
            nftAfter: after,
            nftBefore: before,
            nftFirst: first,
            nftLast: last,
          },
        },
      });

      return queue;
    } catch (err) {
      addErrorNotification({ content: err?.message, isCloseable: true });
    }
  };

  return {
    attachNfts,
    attachNftsLoading: loading,
    attachNftsError: error,
  };
};

export default useAttachNfts;
