import { ReactComponent as MetamaskLogo } from "shared/icons/metamaskLogo.svg";
import Spinner from "shared/ui/Spinner";
import { LoadingSection } from "./styled";

const ConnectButton = ({ onClick, loading, ...restProps }) => {
  return (
    <button
      className="btn btn-primary rounded-pill"
      onClick={onClick}
      {...restProps}
    >
      {loading ? (
        <LoadingSection>
          <Spinner isSmall isWhite /> Loading
        </LoadingSection>
      ) : (
        <>
          <MetamaskLogo width="2.5rem" className="me-3" />
          Connect to MetaMask
        </>
      )}
    </button>
  );
};

export default ConnectButton;
