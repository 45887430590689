import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "api/hooks/useAuth";
import { useWalletConnection } from "context/walletConnectionContext";

import { ConnectButton } from "shared/ui/buttons";
import { DownloadMetamaskLink } from "shared/ui/links";

const ConnectAndLogin = ({ successPath }) => {
  const { account, connect } = useWalletConnection();
  const navigate = useNavigate();
  const isMetaMaskInstalled = window.ethereum !== undefined;

  const authCompleted = ({ authenticate }) => {
    if (authenticate?.user) navigate(successPath);
  };
  const { login, loading } = useAuth(authCompleted);

  useEffect(() => {
    if (isMetaMaskInstalled && account) login(account);
  }, [isMetaMaskInstalled, account, login]);

  if (isMetaMaskInstalled)
    return (
      <ConnectButton onClick={connect} disabled={loading} loading={loading} />
    );

  return <DownloadMetamaskLink />;
};

export default ConnectAndLogin;
