import styled from "styled-components";

export const FormSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.9rem;
  width: 100%;
  max-width: 37.5rem;
`;

export const ActionsWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.9rem;
`;

export const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.63rem;
  margin-bottom: 1rem;
`;

export const DatesLabel = styled.legend`
  display: block;
  margin-bottom: 0.5rem;
  font-size: initial;
`;
