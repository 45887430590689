import { gql } from "@apollo/client";
import queuesFragment from "../fragments/queuesFragment";
import authRuleFragment from "../fragments/authRuleFragment";

export default gql`
  query event(
    $id: Int!
    $queueAfter: String
    $queueBefore: String
    $queueFirst: Int
    $queueLast: Int
    $nftAfter: String
    $nftBefore: String
    $nftFirst: Int
    $nftLast: Int
  ) {
    event(id: $id) {
      id
      title
      description
      startsAt
      endsAt
      archived
      canEdit {
        ...authRule
      }
      canArchive {
        ...authRule
      }
      canReinstate {
        ...authRule
      }
      queues(
        after: $queueAfter
        before: $queueBefore
        first: $queueFirst
        last: $queueLast
      ) {
        ...queues
      }
    }
  }
  ${queuesFragment}
  ${authRuleFragment}
`;
