import styled, { css } from "styled-components";

export const StyledButton = styled.button(({ theme, customStyles }) => {
  const { colors, borderRadius } = theme;

  return css`
    --button-bg: ${colors.primary};
    --button-color: ${colors.white};
    --button-hover-bg: ${colors.primaryDark};

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.75rem;
    color: var(--button-color);
    cursor: pointer;
    user-select: none;
    border: 0.063rem solid var(--button-bg);
    border-radius: ${borderRadius};
    background-color: var(--button-bg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:focus,
    &:hover {
      background-color: var(--button-hover-bg);

      &:focus {
        outline: none;
        box-shadow: 0 0 0 0.25rem rgba(0 0 0 / 15%);
      }
    }

    ${customStyles && customStyles(theme)}
  `;
});
