import { H1 } from "shared/styles/headersStyles";
import styled from "styled-components";

export const Question = styled.p`
  ${H1};
  font-family: ${({ theme: { fonts } }) => fonts.secondary}; ;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.white};
`;
