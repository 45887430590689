import { useLocation } from "react-router-dom";
import { ReactComponent as MetamaskLogo } from "shared/icons/metamaskLogo.svg";

const DownloadMetamaskLink = () => {
  const { pathname } = useLocation();
  const previousPath = pathname.split("/login")[0];

  return (
    <a
      className="btn btn-primary rounded-pill"
      href={`https://metamask.app.link/dapp/${window.location.host}${previousPath}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <MetamaskLogo width="2.5rem" className="me-3" />
      Download MetaMask
    </a>
  );
};

export default DownloadMetamaskLink;
