import useWebShare from "shared/hooks/useWebShare";

import ModalWindow from "shared/ui/ModalWindow";
import QRCode from "shared/ui/QRCode";
import { Button } from "shared/ui/buttons";

import { ModalContent } from "../styled";

const QrCodeModal = ({ isOpen, close, eventId, title }) => {
  const qrCodeId = "event-qr-code";
  const share = useWebShare();
  const eventLink = `${window.location.origin}/event/${eventId}`;

  const shareQrCode = async () => {
    const canvas = document.getElementById(qrCodeId);
    const dataUrl = canvas.toDataURL();
    const blob = await (await fetch(dataUrl)).blob();
    const qrCodeFile = new File([blob], `${qrCodeId}.png`, { type: blob.type });

    await share({
      title,
      text: `${title} qr-code`,
      files: [qrCodeFile],
      fallBackString: eventLink,
    });
  };

  return (
    <ModalWindow isOpen={isOpen} close={close} headerTitle={title}>
      <ModalContent>
        <QRCode id={qrCodeId} value={eventLink} />

        <Button isOutline onClick={shareQrCode}>
          Share Event QR Code
        </Button>
      </ModalContent>
    </ModalWindow>
  );
};

export default QrCodeModal;
