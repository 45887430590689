import truncateEthAddress from "truncate-eth-address";
import useScreenOrientation from "shared/hooks/useScreenOrientation";
import { ImageWrapper, Image, Caption, CaptionItem } from "./styled";

const NftImage = ({ nft }) => {
  const { isPortrait } = useScreenOrientation();
  const { cid, owner } = nft || {};
  // TODO: need creator
  const creator = null;
  const shortAddress = truncateEthAddress(owner);

  return (
    <ImageWrapper isPortraitView={isPortrait}>
      <Image
        src={cid}
        alt={`${shortAddress} NFT`}
        isPortraitView={isPortrait}
      />

      {(owner || creator) && (
        <Caption isPortraitView={isPortrait}>
          {creator && (
            <CaptionItem>
              Creator
              <br />
              {creator}
            </CaptionItem>
          )}

          {owner && (
            <CaptionItem>
              Current owner
              <br />
              {shortAddress}
            </CaptionItem>
          )}
        </Caption>
      )}
    </ImageWrapper>
  );
};

export default NftImage;
