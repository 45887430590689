import { gql } from "@apollo/client";
import allEventsFragment from "../fragments/allEventsFragment";

export default gql`
  query me(
    $archived: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $queueAfter: String
    $queueBefore: String
    $queueFirst: Int
    $queueLast: Int
    $nftAfter: String
    $nftBefore: String
    $nftFirst: Int
    $nftLast: Int
  ) {
    me {
      events(
        archived: $archived
        after: $after
        before: $before
        first: $first
        last: $last
      ) {
        ...allEventsInfo
      }
    }
  }
  ${allEventsFragment}
`;
