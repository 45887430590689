import { useNavigate } from "react-router-dom";

import useCreateEvent from "api/hooks/event/useCreateEvent";
import useUpdateEvent from "api/hooks/event/useUpdateEvent";
import { useNotificationContext } from "context/notificationContext";

const useSaveEvent = ({ eventId, isDefaultGallerySelected }) => {
  const navigate = useNavigate();
  const { createEvent, createLoading } = useCreateEvent();
  const { updateEvent, updateLoading } = useUpdateEvent();
  const { addNotification, addErrorNotification } = useNotificationContext();

  const goToNextPage = (id) => {
    if (isDefaultGallerySelected) {
      navigate(`/admin/event/${id}/select-nfts`);
    } else {
      navigate("/admin/events/active");
    }
  };

  const saveEvent = async (data) => {
    try {
      if (eventId) {
        const event = await updateEvent(data);
        const { id } = event || {};

        addNotification({ content: "Event has been updated!" });
        goToNextPage(id);
      } else {
        const event = await createEvent(data);
        const { id } = event || {};

        goToNextPage(id);
        addNotification({ content: "Event has been created!" });
      }
    } catch (err) {
      addErrorNotification({ content: err?.message, isCloseable: true });
    }
  };

  return {
    saveEvent,
    loading: createLoading || updateLoading,
  };
};

export default useSaveEvent;
