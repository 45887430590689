import styled, { css } from "styled-components";

export const List = styled.ul(
  ({ theme: { down, breakpoints } }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.25rem;
    overflow: hidden;
    margin: 0 0 1.9rem;
    padding: 0;

    ${down(breakpoints.md)} {
      grid-template-columns: 1fr 1fr;
    }

    ${down(breakpoints.sm)} {
      grid-template-columns: 1fr;
    }
  `
);
