import { Button } from "shared/ui/buttons";

import { Wrapper, PageNumber } from "./styled";

const Pagination = ({
  pageInfo,
  setBeforeCursor,
  setAfterCursor,
  pageNumber,
  setPageNumber,
}) => {
  const { hasPreviousPage, hasNextPage, startCursor, endCursor } =
    pageInfo || {};

  const goToPrevPage = () => {
    setBeforeCursor(startCursor);
    setAfterCursor(undefined);
    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const goToNextPage = () => {
    setAfterCursor(endCursor);
    setBeforeCursor(undefined);
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  return (
    <Wrapper>
      <Button
        isOutline
        isSmall
        title="previous page"
        disabled={!hasPreviousPage}
        onClick={goToPrevPage}
      >
        &#8592;
      </Button>

      <PageNumber>{pageNumber}</PageNumber>

      <Button
        isOutline
        isSmall
        title="next page"
        disabled={!hasNextPage}
        onClick={goToNextPage}
      >
        &#8594;
      </Button>
    </Wrapper>
  );
};

export default Pagination;
