import styled from "styled-components";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Separator = styled.span`
  display: flex;
  margin: 1rem auto;
  font-weight: bold;
`;

export const Text = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.yellow};
  font-weight: bold;
`;
