import { gql } from "@apollo/client";
import nftsFragment from "./nftsFragment";

export default gql`
  fragment queues on QueueTypeConnection {
    edges {
      cursor
      node {
        id
        monitorsCount
        nfts(
          after: $nftAfter
          before: $nftBefore
          first: $nftFirst
          last: $nftLast
        ) {
          ...nfts
        }
      }
    }

    nodes {
      id
      monitorsCount
      nfts(
        after: $nftAfter
        before: $nftBefore
        first: $nftFirst
        last: $nftLast
      ) {
        ...nfts
      }
    }
  }
  ${nftsFragment}
`;
