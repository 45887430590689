import { useNavigate } from "react-router-dom";

import useGetUserInfo from "api/hooks/user/useGetUserInfo";
import { Button } from "shared/ui/buttons";
import Loader from "shared/ui/Loader";

import { WelcomeSection } from "./styled";

const Welcome = () => {
  const navigate = useNavigate();
  const { canCreateEvent, loading } = useGetUserInfo();

  if (loading) return <Loader />;

  return (
    <WelcomeSection>
      <h1>Welcome!</h1>
      {canCreateEvent && (
        <Button onClick={() => navigate("/admin/event/new")}>New Event</Button>
      )}
    </WelcomeSection>
  );
};

export default Welcome;
