import useGetEventById from "api/hooks/event/useGetEventById";
import Loader from "shared/ui/Loader";
import EventForm from "components/admin/EventForm";

const Event = () => {
  const { currentEvent, loading: eventLoading } = useGetEventById();

  if (eventLoading) return <Loader />;

  return <EventForm currentEvent={currentEvent} />;
};

export default Event;
