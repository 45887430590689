const InputField = ({
  id,
  name,
  type = "text",
  label,
  error,
  ...restProps
}) => {
  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}

      <input
        type={type}
        name={name}
        className="form-control"
        id={id}
        {...restProps}
      />
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
};

export default InputField;
