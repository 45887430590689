import styled, { css } from "styled-components";

export const ImageWrapper = styled.figure(
  ({ isPortraitView }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    ${isPortraitView && "flex-direction: column"};
  `
);

export const Image = styled.img(
  ({ isPortraitView }) => css`
    max-width: 100%;
    max-height: 100%;
    ${isPortraitView ? "width: 100%" : "height: 100%"};
    object-fit: contain;
  `
);

export const Caption = styled.figcaption(
  ({ theme: { colors } }) => css`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    gap: 1.9rem;
    width: 100%;
    color: ${colors.white};
  `
);

export const CaptionItem = styled.strong(
  ({ theme: { colors } }) => css`
    text-shadow: 0 0 0.2rem ${colors.black}, 0 0 0.1rem ${colors.grayF4};
  `
);
