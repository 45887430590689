import styled, { css } from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.9rem;
  flex-grow: 1;
`;

export const Actions = styled.div(
  ({ theme: { down, breakpoints } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.9rem;

    ${down(breakpoints.sm)} {
      flex-direction: column;
    }
  `
);
