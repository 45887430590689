import styled, { css } from "styled-components";
import { Button, TextButton } from "../buttons";

export const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const actionStylesWithArrow = ({ theme: { colors }, withArrow, isOpen }) => css`
  position: relative;
  display: flex;
  ${withArrow &&
  css`
    &::after {
      display: inline-block;
      content: "";
      margin-left: 0.255rem;
      vertical-align: 0.255rem;
      border-top: 0.3rem solid;
      border-bottom: 0;
      border-right: 0.3rem solid transparent;
      border-left: 0.3rem solid transparent;
      ${isOpen &&
      css`
        border-top: 0;
        border-bottom: 0.3rem solid;
      `}
    }
  `}
`;

export const ActionButton = styled(Button)((props) =>
  actionStylesWithArrow(props)
);

export const ActionLink = styled(TextButton)(
  ({ theme, ...restProps }) => css`
    &:hover {
      text-decoration: none;
      color: ${theme.colors.primary};
    }

    ${actionStylesWithArrow({ theme, ...restProps })}
  `
);

export const DropdownMenu = styled.div(
  ({ theme: { colors, zIndex, borderRadius } }) => css`
    position: absolute;
    top: calc(100% + 0.63rem);
    right: 0;
    overflow: hidden;
    z-index: ${zIndex.contentMin};
    min-width: 11.25rem;
    max-width: 100%;
    border-radius: ${borderRadius};
    border: 0.06rem solid ${colors.grayAD};
    background-color: ${colors.white};
    list-style: none;
  `
);
