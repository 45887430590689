const useGetGalleries = () => {
  return [
    {
      label: "Let Me Choose NFT’s from my Wallet",
      value: "walletNfts",
    },
    {
      label: "Empty gallery",
      value: "emptyGallery",
    },
  ];
};

export default useGetGalleries;
