import { gql } from "@apollo/client";
import authRuleFragment from "./authRuleFragment";
import queuesFragment from "./queuesFragment";

export default gql`
  fragment eventFragment on EventType {
    id
    title
    description
    queuesCount
    queues(
      after: $queueAfter
      before: $queueBefore
      first: $queueFirst
      last: $queueLast
    ) {
      ...queues
    }
    startsAt
    endsAt
    archived
    canEdit {
      ...authRule
    }
    canArchive {
      ...authRule
    }
    canReinstate {
      ...authRule
    }
  }
  ${authRuleFragment}
  ${queuesFragment}
`;
