import { forwardRef } from "react";
import { ReactComponent as TickIcon } from "shared/icons/tick.svg";

import {
  CheckboxWrapper,
  NativeCheckbox,
  CustomCheckbox,
  Label,
} from "./styled";

const Checkbox = (
  {
    id,
    name,
    value,
    checked,
    children,
    onChange,
    isRounded = false,
    isYellow = false,
    isPositionedByLabel = false,
    additionalClasses,
    ...restProps
  },
  ref
) => {
  return (
    <CheckboxWrapper
      className={additionalClasses}
      isPositionedByLabel={isPositionedByLabel}
      isYellow={isYellow}
      {...restProps}
    >
      <NativeCheckbox
        ref={ref}
        id={id}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />

      <CustomCheckbox isRounded={isRounded}>
        {checked && <TickIcon />}
      </CustomCheckbox>
      {children && (
        <Label htmlFor={id} isPositionedByLabel={isPositionedByLabel}>
          {children}
        </Label>
      )}
    </CheckboxWrapper>
  );
};
export default forwardRef(Checkbox);
