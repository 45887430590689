import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useGetEventById from "api/hooks/event/useGetEventById";
import useAttachNfts from "api/hooks/nft/useAttachNfts";
import useGetWalletNfts from "shared/hooks/useGetWalletNfts";

import Loader from "shared/ui/Loader";
import NftList from "components/shared/NftList";
import { Button } from "shared/ui/buttons";

const SelectableNfts = () => {
  const { nfts: userNfts, loading } = useGetWalletNfts();
  const { currentEvent } = useGetEventById();
  const { attachNfts } = useAttachNfts();
  const navigate = useNavigate();

  const { title, queues } = currentEvent || {};
  const { id: queueId } = queues?.nodes?.[0] || {};
  const [nfts, setNfts] = useState([]);

  const addRemoveNft = (currentId, isSelected) => {
    if (isSelected) {
      setNfts((prev) => [...prev, currentId]);
    } else {
      setNfts((prev) => prev.filter((id) => id !== currentId));
    }
  };

  const saveNfts = () => {
    attachNfts({ queueId, cids: nfts });
    navigate("/admin/events/active");
  };

  if (loading) return <Loader />;

  return (
    <>
      {title && <h1>{title}</h1>}
      <NftList
        isSelectable
        nfts={userNfts}
        loading={loading}
        addRemoveNft={addRemoveNft}
      />

      {nfts?.length > 0 && (
        <footer>
          <Button onClick={saveNfts}>Create</Button>
        </footer>
      )}
    </>
  );
};

export default SelectableNfts;
