import { gql } from "@apollo/client";
import allEventsFragment from "../fragments/allEventsFragment";

export const GENERATE_NONCE = gql`
  mutation generateNonce($input: InputsGenerateNonceInput!) {
    generateNonce(input: $input) {
      nonce
      ethAccount
    }
  }
`;

export const AUTHENTICATE = gql`
  mutation authenticate(
    $input: InputsAuthenticateInput!
    $archived: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $queueAfter: String
    $queueBefore: String
    $queueFirst: Int
    $queueLast: Int
    $nftAfter: String
    $nftBefore: String
    $nftFirst: Int
    $nftLast: Int
  ) {
    authenticate(input: $input) {
      user {
        id
        ethAccount
        events(
          archived: $archived
          after: $after
          before: $before
          first: $first
          last: $last
        ) {
          ...allEventsInfo
        }
      }
      accessToken
    }
  }
  ${allEventsFragment}
`;
